<template>
  <b-modal
    id="modal-facility"
    ref="refModal"
    title="Cơ sở"
    ok-title="Lưu"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <!-- <avatar-view align="center" /> -->
            <image-view
              v-model="itemLocal.avatar"
              align="center"
              :src="itemLocal.avatar"
              :width="100"
              :height="100"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Tên cơ sở <span class="text-danger">(*)</span>
                </template>
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="HomeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="CH-01"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Tuyến"
              rules="required"
            >
              <select-level
                v-model="itemLocal.level"
                required
                :default-value="itemLocal.level"
                label="Tuyến"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- Địa chỉ -->
          <b-col
            v-if="!isDepartmentOfHealth()"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="province"
              rules="required"
            >
              <select-province
                v-model="itemLocal.provinceObject"
                required
                :default-value="itemLocal.provinceObject"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="district"
              rules="required"
            >
              <select-district
                v-model="itemLocal.districtObject"
                :province="itemLocal.provinceObject"
                required
                :default-value="itemLocal.districtObject"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="ward"
              rules="required"
            >
              <select-ward
                v-model="itemLocal.wardObject"
                :district="itemLocal.districtObject"
                required
                :default-value="itemLocal.wardObject"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="address"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Địa chỉ chi tiết <span class="text-danger">(*)</span>
                </template>
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MapPinIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="address"
                    v-model="itemLocal.address_detail"
                    placeholder="Số nhà 12, Khuất Duy Tiến"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- submit -->
        </b-row>
        <b-col
          v-if="itemLocal.level && (itemLocal.level.value === 'huyen' || itemLocal.level === 'huyen')"
          cols="12"
          class="mt-2"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="itemLocal.is_health_centre"
              name="check-button"
              switch
              inline
            >
              Trung tâm y tế huyện
            </b-form-checkbox>
          </b-form-group>
        </b-col>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BRow,
  BCol,
  BInputGroupPrepend,
  BFormCheckbox,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectProvince from '@/views/components/SelectProvince.vue';
import ImageView from '@/views/components/ImageView.vue';
import { isDepartmentOfHealth } from '@/auth/utils';
import SelectDistrict from '@/views/components/SelectDistrict.vue';
import SelectWard from '@/views/components/SelectWard.vue';
import SelectLevel from '@/views/components/SelectLevel.vue';
import useFacilityModal from './useFacilityModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    SelectProvince,
    SelectDistrict,
    SelectWard,
    ImageView,
    SelectLevel,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useFacilityModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      isDepartmentOfHealth,
    };
  },
};
</script>
