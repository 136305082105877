import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchManagers(ctx, data) {
      return useJwt.getFacilityAdmins(data).then(response => response);
    },
    createManager(ctx, data) {
      return useJwt.createFacilityAdmin(data).then(response => response);
    },
    updateManager(ctx, data) {
      return useJwt.updateFacilityAdmin(data).then(response => response);
    },
    deleteManager(ctx, id) {
      return useJwt.deleteManager(id).then(response => response);
    },
  },
};
