import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFacilities(ctx, data) {
      return useJwt.getFacilities(data).then(response => response);
    },
    createFacility(ctx, data) {
      return useJwt.createFacility(data).then(response => response);
    },
    updateFacility(ctx, data) {
      return useJwt.updateFacility(data).then(response => response);
    },
    deleteFacility(ctx, id) {
      return useJwt.deleteFacility(id).then(response => response);
    },
  },
};
