/* eslint-disable camelcase */
import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from "@/utils/toast";
import managerStoreModule from '../managerStoreModule';

export default function useManagerModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'facility-manager';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, managerStoreModule);
  }

  // Use toast
  const toastification = toast();
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const {
      full_name, phone, email, status, password, facility,
    } = itemLocal.value;

    const data = {
      full_name,
      phone,
      email,
      password,
      medical_facility: facility._id,
    };

    if (status && status.value) {
      data.status = status.value;
    } else {
      data.status = status;
    }

    if (itemLocal.value._id) {
      data._id = itemLocal.value._id;
      if (itemLocal.value.role) {
        data.role = itemLocal.value.role;
      }
      if (itemLocal.value.medical_facility) {
        data.medical_facility = itemLocal.value.medical_facility;
      }
      store
        .dispatch('facility-manager/updateManager', data)
        .then(() => { })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    } else {
      store
        .dispatch('facility-manager/createManager', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
